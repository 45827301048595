export default {
  namespaced:true,

  state: () => ({
    canal:null,
    cliente:null,
    display:null, // object del tv_display
    regiones:[], // array de regiones de la tv display
    video_canal:null, // url de los videos del canal
  }),


  getters:{

  },

  mutations:{

    setReproductor(state,data){
      state.canal = data.canal;
      state.cliente = data.cliente;
      state.display = data.display;
      state.regiones = data.regiones;
      state.video_canal = data.video_canal;
    }
  },

  actions:{
    fetchContenido({commit},{canalName,clienteName}) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/reproductor`,{canalName,clienteName}).then(({data}) => {
          commit('setReproductor',data);
          resolve(data);
        }).catch(e => reject(e));          
      })
    }
  }
}