import { headers } from "@core/utils/utils"
export default {
  namespaced:true,
  state:() =>({
    
    cliente:{
      id:null,
      nombre:'',
      logo:null,
      logo_fondo_negro:null,
      telefono:null,
      email:null,
      sitio_web:null,
      direccion:'',
      lat:20.6565177,
      lng:-105.211358,
      ciudad_id:null,
      estado_id:null,
      facturar:false,
      datos_facturacion:{
        rfc:null,
        banco:'',
        constancia:null,
        nombre_fiscal:'',
        ultimos_digitos:null,
        domicilio_fiscal:null
      },
      icon_map:null,
      tipo_cliente_id:null,
      tipo_cliente:null,
      activo:false,
      areas:[],
      responsables:[],
      disk:'',
      divisa_id:null,
      divisa:null,
      iva_id:null,
      iva:null,
      cuenta:null,
      odts:0,
      iata:''
    },

    clientes:[]

  }),

  getters:{
    draft:(state) => clone(state.cliente),
    datosFacturacionForm : (state) => clone(state.cliente.datos_facturacion) ,


    clientesSeleccionables:(state) => {
      let user = JSON.parse(localStorage.getItem('userData'))

      if(user){
        return user.clientes.map(v => ({id:v.id,label:v.nombre,logo:v.logo}))
      }
      return []
    },


    tipoClienteName(state){
      return state.cliente.tipo_cliente ? state.cliente.tipo_cliente.nombre : 'N/A';
    },

    diskCliente:(state) => state.cliente.disk ?? '',

    clienteId:(state) => state.cliente.id ?? null,
   
  },



  mutations:{

    clear(state){
      state.cliente = {
        id:null,
        nombre:'',
        logo:null,
      logo_fondo_negro:null,
        telefono:null,
        email:null,
        sitio_web:null,
        direccion:'',
        lat:20.6565177,
        lng:-105.211358,
        ciudad_id:null,
        estado_id:null,
        facturar:false,
        datos_facturacion:{
          rfc:null,
          banco:'',
          constancia:null,
          nombre_fiscal:'',
          ultimos_digitos:null,
          domicilio_fiscal:null
        },
        icon_map:null,
        tipo_cliente_id:null,
        tipo_cliente:null,
        activo:false, 
        areas:[],
        responsables:[],
        disk:'',
        divisa_id:null,
        divisa:null,
        iva_id:null,
        iva:null,
        cuenta:null,
        odts:0,
        iata:''
      }
    },


    setClientes(state,clientes){
      if(clientes){
        state.clientes = clientes
      }
    },


    setCliente(state,cliente){
      if( cliente){
        state.cliente = cliente

        let user_data = JSON.parse(localStorage.getItem('userData'))
        let cliente_user = user_data.cliente;
        
        if(cliente_user){
          if(cliente_user.id === state.cliente.id){
             user_data.cliente = cliente
            localStorage.setItem('userData',JSON.stringify(user_data))
          }
        }
      }
    },

    pushArea(state,area){
      
      if(state.cliente.id === area.cliente_id){
        state.cliente.areas.push(area)
      }
    },

    spliceArea(state,area_id) {
      state.cliente.areas.splice(
        state.cliente.areas.findIndex(v => v.id === area_id),
        1
      )
    },

    updateArea(state,area){
      let i = state.cliente.areas.findIndex(a => a.id === area.id);

      if(i != -1){
        state.cliente.areas[i] = area
      }
    },
    
    pushResponsable(state,responsable){
      
      if(state.cliente.id === responsable.cliente_id){
        let user_data = JSON.parse(localStorage.getItem('userData'))
        let cliente = user_data.cliente;
        
        if(cliente){
          if(cliente.id === state.cliente.id){
             cliente.responsables.push(responsable)
             user_data.cliente = cliente
            localStorage.setItem('userData',JSON.stringify(user_data))
          }
        }

        state.cliente.responsables.push(responsable)
      }
    },

    spliceResponsable(state,responsable_id) {

      let user_data = JSON.parse(localStorage.getItem('userData'))
      let cliente = user_data.cliente;
      
      if(cliente){
        if(cliente.id === state.cliente.id){
            cliente.responsables.splice(
              cliente.responsables.findIndex(v => v.id === responsable_id),
              1
            );
            user_data.cliente = cliente
          localStorage.setItem('userData',JSON.stringify(user_data))
        }
      }
      
      state.cliente.responsables.splice(
        state.cliente.responsables.findIndex(v => v.id === responsable_id),
        1
      )
    },

    updateResponsable(state,responsable){
      let i = state.cliente.responsables.findIndex(a => a.id === responsable.id);

      if(i != -1){
        state.cliente.responsables[i] = responsable
      }
    },

  
    actualizarCuentaCliente(state,{cuenta,cliente_id}){
        if(state.cliente.id === cliente_id){
          state.cliente.cuenta = cuenta
        }
    },

    actualizarAvatarResponsable(state,{responsable_id,avatar}){
      let i = state.cliente.responsables.findIndex(v => v.id === responsable_id);
      if(i != -1){
        state.cliente.responsables[i].avatar = avatar

        let user_data = JSON.parse(localStorage.getItem('userData'))
        let cliente = user_data.cliente;
      
        if(cliente){
          if(cliente.id === state.cliente.id){
            cliente.responsables[i].avatar = avatar
            user_data.cliente = cliente
            localStorage.setItem('userData',JSON.stringify(user_data))
          }
        }

      }
    },


    updateCuenta(state,{cuenta,cliente_id}) {
      if(cuenta && cliente_id == state.cliente.id){
          state.cliente.cuenta = cuenta;

          let user_data = JSON.parse(localStorage.getItem('userData'))
          let cliente = user_data.cliente;
          
          if(cliente){
            if(cliente.id === state.cliente.id){
              user_data.cliente = state.cliente;
              localStorage.setItem('userData',JSON.stringify(user_data))
            }
          }
      }
    }

    

  },


  actions:{

    fetch({commit},cliente_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/fetch-data`).then(({data}) => {
          commit('setCliente',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/clientes/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    
    guardar({commit},datos){

      let formData = new FormData();
      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k]);
      })

      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append("_method","PUT")
          axios.post(`/api/clientes/${datos.id}`,formData,{headers}).then(({data}) => {
            
              if(data.result){
                commit('setCliente',data.cliente)
              }

              resolve(data)
          }).catch(e => reject(e))
        }else{
          
          axios.post(`/api/clientes`,formData,{headers}).then(({data}) => {
            resolve(data)
          }).catch( e => reject(e))

        }
      })
    },

    eliminar({state,commit},cliente_id){

      return new Promise((resolve, reject) => {
        axios.delete(`/api/clientes/${cliente_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    toggleActive({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/toggle-active`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },

    
    guardarDatosFacturacion({state},datos){
      let formData = new FormData();

      Object.keys(datos).forEach(k => {
        formData.append(k,datos[k])
      })
      formData.append('_method','PUT');

      return new Promise((resolve, reject) => {
        axios.post(`/api/clientes/${state.cliente.id}/guardar/datos-facturacion`,formData,{headers})
        .then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },

    getEmpleados({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/get/empleados`)
        .then(({data}) => resolve(data))
        .catch(e => reject(e))
      })
    },
    getSolicitudesUltimoMes({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/get/solicitudes-ultimo-mes`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    getSolicitudesGeneracionContenidoUltimoMes({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/get/solicitudes-generacion-contenido-ultimo-mes`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    getTotalesOdts({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/get/totales-odts`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },

    getTotalInversion({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/clientes/${cliente_id}/get/total-inversion`).then(({data}) => resolve(data)).catch(e => reject(e));
      })
    },
    
    
    fetchContenidosSolicitados({commit},datos){
      return new Promise((resolve, reject) => {
        axios.post(`/api/clientes/fetch-contenidos-solicitados`,datos)
        .then(({data}) => {
          resolve(data);
        })
        .catch(e => {
          reject(e);
        })
      })
    }



  }

}