import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import '@/assets/scss/app.scss'
Vue.prototype.$eventHub = new Vue();

import '@axios'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './filters'


import i18n , {loadLocaleAsync} from '@/libs/i18n'


// 3rd party plugins

import './libs/acl'

import './libs/toastification'
import './libs/sweet-alerts'
import './libs/vue-select'
import './directives'

// // BSV Plugin Registration
// Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)

import '@/assets/scss/style.scss'


import jQuery from 'jquery';
window.$ = window.jQuery = jQuery

import _ from 'lodash';

window._ = _;

// Laravel Echo
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost:import.meta.env.VITE_REVERB_HOST,
    wsPort:80,
    wssPort:import.meta.env.VITE_REVERB_WSS_PORT,
    forceTLS:true,
    enabledTransports: ['ws','wss'],
    authorizer:(channel,options) => {
      return {
        authorize: (socketId,next) => {
          axios.post('/broadcasting/auth',{
            socket_id:socketId,
            channel_name:channel.name
          }).then(response => {
            next(null,response.data)
          }).catch(error => next(error))
        }
      }
    }
});


Vue.config.productionTip = import.meta.env.DEV;
Vue.config.silent = import.meta.env.PROD;
Vue.config.devtools = import.meta.env.DEV;

let app = new Vue({
  store,
  data:() => ({
    api_uri:import.meta.env.VITE_API_URL,
    app_url:import.meta.env.VITE_APP_URL,
    // loading:computed(() => store.state.loading)
  }),
  router,
  i18n,
  render: h => h(App),
  provide:() =>  ({swal : Vue.swal,i18n:i18n,loadLocaleAsync:loadLocaleAsync,app_url:import.meta.env.VITE_APP_URL}),
}).$mount('#app')
