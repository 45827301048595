export default {
  namespaced:true,
  state:() => ({
    mensaje:{
      id:null,
      chat_bot_id:null,
      tipo:null,
      mensaje:null,
      response_id:null,
      created_at:null,
      updated_at:null,
      multilinea:false,
      origen:1, // 1: chatbot, 2:usuario
      parametros:[],
      payload:[],
    },
    mensajes:[],
    
  }),


  getters:{
    getOrigen:(state) => (mensaje) => {
      let legends = ['Concierge','Usuario'];
      return legends[mensaje.origen - 1];  
    }
  },


  mutations:{
    setMensajes(state,data){
      if(data){
        state.mensajes = data;
      }
    },

    setMensaje(state,data){
      if(data){
        state.mensaje = data;
      }
    }

  },


  actions:{
    
    enviar({commit},datos){
      
    }
  }

}

