import esFlag from '@images/flags/es.png'
export default{
  namespaced:true,
  state(){
    return{
      idioma:{
        id            : null,
        idioma_id     : null,
        cliente_id    : null,
        flag          : null,
        predeterminado: false,
        cliente       : null,
        idioma        : null
      },

      idiomas:[],
      locales:[{locale:'es',img:esFlag,name:'Español'}]
    }
  },


  getters:{
    draft(state){
      return state.idioma
    }
  },

  mutations:{

    clear(state){
      state.idioma = {
        id            : null,
        idioma_id     : null,
        cliente_id    : null,
        flag          : null,
        predeterminado: false,
        cliente       : null,
        idioma        : null
      }
    },

    setIdioma(state,idioma){
      if(idioma){
        state.idioma = idioma
      }
    },
    
    setIdiomas(state,idiomas){
      state.idiomas = idiomas
    },



    put(state,idioma_id){

      if(state.idiomas.length){
        state.idiomas.splice(
          state.idiomas.findIndex(i => i.id === idioma_id),
          1
        )
      }
    },

  },

  actions:{

    fetch({commit},idioma_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/cliente-idiomas/${idioma_id}/fetch-data`).then(({data}) => {
          commit('setIdioma',data)
          resolve(data)
        }).catch(e  => reject(e))

      })
    },
    
    fetchData({commit},filtro){
      return new Promise((resolve, reject) => {
        axios.post(`/api/cliente-idiomas/fetch-data`,filtro).then(({data})  => {
          commit('setIdiomas',data.idiomas)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    eliminar({commit},idioma_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/cliente-idiomas/${idioma_id}`).then(({data}) => {
          if(data.result){
            commit('put',idioma_id)
          }

          resolve(data)
        }).catch(e => reject(e))
      })
    },


   guardar({commit},datos){
    let formData = new FormData();

    Object.keys(datos).forEach(v => {
      formData.append(v,datos[v])
    }) 

    const headers = {
      headers:{
        ContentType:'multipart/form-data'
      }
    };


    return new Promise((resolve, reject) => {
      if(datos.id){

        formData.append('_method','PUT');

        axios.post(`/api/cliente-idiomas/${datos.id}`,formData,headers).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      }else{

        axios.post(`/api/cliente-idiomas`,formData,headers).then(({data}) => {
          if(data.result){
            commit('setIdioma',data.idioma)
          }
          resolve(data)
        }).catch(e => reject(e))

      }
    })
   },

  }
}