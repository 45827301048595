export default {
  namespaced:true,
  state:() =>({
  
    contenido:{
      id       : null,
      pagina_id: null,
      contenido: null,
      locale   : null,
      pagina   : null
    },

    contenidos:[],
  }),

  getters:{
    draft:(state) => clone(state.contenido),
  },



  mutations:{

    clear(state){
      state.contenido = {
        id       : null,
        pagina_id: null,
        contenido: null,
        locale   : null,
        pagina   : null
      }
    },


    setContenidos(state,contenidos){
      if(contenidos){
        state.contenidos = contenidos
      }
    },


    setContenido(state,contenido){
      if(contenido){
        state.contenido = contenido
      }
    },



    update(state,contenido){
      let i = state.contenidos.findIndex(c => c.id == contenido.id)

      if(i != -1){
        state.contenidos[i] = contenido
      }

      if(state.contenido.id == contenido.id){
        state.contenido = contenido
      }
    }

  },


  actions:{

    fetch({commit},contenido_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/pagina-contenidos/${contenido_id}/fetch-data`).then(({data}) => {
          commit('setContenido',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    fetchContenidos({commit},pagina_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/pagina-contenidos/paginas/${pagina_id}/fetch-data`).then(({data}) => {
          if(data.contenidos.length){
            commit('setContenido',data.contenido)
            commit('setContenidos',data.contenidos)
          }
          
          resolve(data)
        }).catch(e => {
          reject(e)
        })
      })
    },



    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/pagina-contenidos/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      
      return new Promise((resolve, reject) => {
        if(datos.id){
           axios.put(`/api/pagina-contenidos/${datos.id}`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.contenido)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
           axios.post(`/api/pagina-contenidos`,datos).then(({data}) => {
            if(data.result){
              commit('setContenido',data.contenido)
            }
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},contenido_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/pagina-contenidos/${contenido_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }
  }

}