export default {
  namespaced:true,
  state:() =>({
  
    color:{
      id        : null,
      color     : '',
      hex_color : '',
      cliente_id: null,
    },

    colors:[],
  }),

  getters:{
    draft:(state) => clone(state.color),
  },



  mutations:{

    clear(state){
      state.color = {
        id        : null,
        color     : '',
        hex_color : '',
        cliente_id: null,
      }
    },


    setColors(state,colors){
      if(colors){
        state.colors = colors
      }
    },


    setColor(state,color){
      if( color){
        state.color = color
      }
    },



    update(state,color){
      let i = state.colors.findIndex(c => c.id == color.id)

      if(i != -1){
        state.colors[i] = color
      }

      if(state.color.id == color.id){
        state.color = color
      }
    },

    pushColor(state,color){
      if(color){
        state.colors.push(color)
      }
    }

  },


  actions:{

    fetch({commit},color_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/colors/${color_id}/fetch-data`).then(({data}) => {
          commit('setColor',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/colors/fetch-data`,datos).then(({data}) => {
          commit('setColors',data.colores)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      
      return new Promise((resolve, reject) => {
        if(datos.id){
           axios.put(`/api/colors/${datos.id}`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.color)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
           axios.post(`/api/colors`,datos).then(({data}) => {
            if(data.result){
              commit('pushColor',data.color)
            }
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},color_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/colors/${color_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },


    cargarColorsPorCliente({commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/colors/cliente/${cliente_id}/fetch-data`).then(({data}) => {
            commit('setColors',data)
            resolve(data)
          }).catch(e => reject(e))
       });
      }
  }

}