import { headers } from "../../@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
  
    pagina:{
      id        : null,
      titulo    : '',
      url       : null,
      banner    : null,
      banner_url: null,
      contenido : null,
      menu      : null,
      contenidos:[]
    },

    paginas:[],
  }),

  getters:{
    draft:(state) => clone(state.pagina),
  },



  mutations:{

    clear(state){
      state.pagina = {
        id: null,
        titulo: "",
        url: null,
        banner: null,
        banner_url: null,
        contenido: null,
        menu: null,
        contenidos: [],
      };
    },


    setPaginas(state,paginas){
      if(paginas){
        state.paginas = paginas
      }
    },


    setPagina(state,pagina){
      if(pagina){
        state.pagina = pagina
      }
    },



    update(state,pagina){
      let i = state.paginas.findIndex(c => c.id == pagina.id)

      if(i != -1){
        state.paginas[i] = pagina
      }

      if(state.pagina.id == pagina.id){
        state.pagina = pagina
      }
    }

  },


  actions:{

    fetch({commit},pagina_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/paginas/${pagina_id}/fetch-data`).then(({data}) => {
          commit('setPagina',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/paginas/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchPorUrl({commit},{url,url_concierge}){
      return new Promise((resolve, reject) => {
        axios.post(`/api/paginas/fetch-data-por-url`,{url,url_concierge}).then(({data}) => {
            commit('setPagina',data)
            resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();
      Object.keys(datos).forEach(key => {
        if(datos[key] != null){
          formData.append(key,datos[key])
        }
      });
      
      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
           axios.post(`/api/paginas/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.pagina)
            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
           axios.post(`/api/paginas`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('setPagina',data.pagina)
            }
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},pagina_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/paginas/${pagina_id}`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }
  }

}