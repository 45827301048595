import store from "@/store";
import {computed,toRefs} from 'vue';

export default [

  // Banco Audiovisual
   {
      path:'/banco-audiovisual/directorio',
      component:() => import('components/Views.vue'),
      children:[
        {
          path:'',
          props:(route) => ({
              disk:route.query.disk,
              directory:route.query.directory,
              isCliente:true
          }),
          name:'banco.audiovisual',
          component:() => import('views/directorios/directorio.vue'),
          meta:{
              pageTitle:'Directorio',
              resource:'Banco audiovisual',
              action:'read',
              breadcrumb:[
                {text:({query}) => query.disk ? query.disk.toUpperCase() : '',active:true},
              ]
          },
        },
        {
            path:':carpetaId/archivos',
            props:(route) => ({
               carpetaId:route.params.carpetaId,
               disk:route.query.disk,
               isCliente:true
            }),
            name:'directorio.archivos',
            component:() => import('views/directorios/archivos.vue'),
            beforeEnter:(to,from,next) => {
               store.dispatch('carpeta/fetch',to.params.carpetaId).then(({id}) => {
                  if(id){
                     next();
                  }
               })
            },

            meta:{
               pageTitle:'Archivos',
               resource:'Banco audiovisual',
               action:'read',
               navActiveLink:'banco.audiovisual',
               breadcrumb:[
                  {text:({query}) => query.disk ? query.disk.toUpperCase() : 'Directorio',active:true},
                  {text:'Archivos',active:true},
               ]
            }
         }
      ]
      
  },  

   //   Canales internos

   {
      path:'/canales-internos',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'canales',
            component:() => import('views/canales/list.vue'),
            meta:{
               resource:'Canales internos',
               action:'read',
               pageTitle:'Medios de Comunicación internos',
            }
         },
         {
            path:':id/configurar',
            name:'canal.configurar',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('canal/fetch',to.params.id).then(({id}) => {
                  if(id){
                     next();
                  }
               });
            },
            component:() => import('views/canales/configurar.vue'),
            meta:{
               resource:'Canales internos',
               action:'write',
               pageTitle:'Configuración de canal',
               navActiveLink:'canales',
               breadcrumb:[
                  {text:'Canales',to:{name:'canales'},active:false},
                  {text:'Configurar',active:true},
               ]
            }
         },

          {
            path:':id/programacion/canal',
            name:'canal.programacion',
            props:true,
            beforeEnter:(to,from,next) => {
               store.dispatch('canal/fetch',to.params.id).then(({id,display_id}) => {
                  if(id){
                     store.dispatch('region/fetchRegionsForDisplay',display_id).then((regions) =>{
                        if(regions.length)   {
                           next();
                        }
                     })
                  }
               });
            },
            component:() => import('views/canales/programacion/programacion.vue'),
            meta:{
               resource:'Programacion de canal',
               action:'read',
               pageTitle:()  => `Programación del canal (${store.getters['canal/nombreCanal']})`,
               navActiveLink:'canales',
               breadcrumb:[
                  {text:'Canales',to:{name:'canales'},active:false},
                  {text:'Programación',active:true},
               ]
            }
         },

      ],
   },


   //  Solicitudes de transmisión
   {
      path:'/solicitudes-transmision',
      component:() => import('components/Views.vue'),
      children:[
         {
            path:'',
            name:'solicitudes',
            component:() => import('views/solicitudes/list.vue'),
            meta:{
               resource:'Solicitudes de transmisión',
               action:'read',
               pageTitle:'Solicitudes de transmisión',
               breadcrumb:[
                  {text:'Listado de solicitudes',active:true},
               ]
            }
         },
         {
            path:'create',
            name:'solicitud.create',
            props:(route) => ({
               canalName:route.query.canalName,
               regionName:route.query.regionName
            }),
            component:() => import('views/solicitudes/create.vue'),
            meta:{
               resource:'Solicitudes de transmisión',
               action:'write',
               pageTitle:'Crear solicitud de transmisión',
               navActiveLink:'solicitudes',
               breadcrumb:[
                  {text:'Solicitudes',to:{name:'solicitudes'},active:false},
                  {text:'Crear',active:true},
               ]
            }
         }
      ]
   },

   // Tienda de clientes
   {
      path:'/tienda-clientes',
      component:() => import('components/Views.vue'),
     
      children:[
         {
            path:'',
            name:'tienda',
            props:(route) => ({
               categoria:route.query.categoria
            }), 
            component:() => import('views/tienda/tienda.vue'),
            meta:{
               resource:'Tienda de clientes',
               action:'read',
               pageTitle:'Tienda de productos y servicios',
            }
         },
          {
            path: ':id/show',
            props: true,
            component: () => import('views/tienda/perfil.vue'),
            name: 'tienda.producto',
            beforeEnter:(to,from,next) => {
               const {cliente} = toRefs(store.state.cliente)
               const divisa_id  = computed(() => cliente.value.divisa_id)

             store.dispatch('producto/fetchProductTienda',{producto_id:to.params.id,divisa_id:divisa_id.value}).then(({id}) => {
                  if(id){
                     next()
                  }
               })
            },
            meta: {
               resource: 'Tienda de clientes',
               action: 'read',
               pageTitle: 'Producto',
               navActiveLink: 'tienda',
              
               breadcrumb: [
                  { text: 'Tienda', active: false, to: { name: 'tienda' } },
                  { text: (route) => {
                     const {producto} = toRefs(store.state.producto)
                     console.log(producto.value.nombre)
                     return producto.value.nombre;
                  }, active: true },
               ]
            }
         }
      ]
   },


  

   // Caja
   {
      path:'/caja',
      name:'caja',
      component: () => import('views/tienda/caja.vue'),
      meta:{
         resource:'Tienda de clientes',
         action:'read',
         pageTitle:'Caja',
         breadcrumb:[
            {text:'tienda',to:{name:'tienda'},active:false},
            {text:'caja',active:true},
         ]
      }
   },

    // Compras
   {
      path:'/mis-compras',
      name:'mis.compras',
      component: () => import('views/compras/misCompras.vue'),
      meta:{
         resource:'Mis compras',
         action:'read',
         pageTitle:'Mis compras',
      }
   },

   // // Servicios
   // {
   //    path:'/servicios',
   //    component:() => import('components/Views.vue'),
   //    children:[
   //       {  
   //          name:'',
   //          component:() => import('views/servicios/servicios.vue'),
   //          meta:{
   //             resource:'Servicios',
   //             action:'read',
   //             pageTitle:'Servicios',
   //          }
   //       },
   //    ]
   // }
   

];
