export default {
  namespaced: true,
  state: () => ({
    chatbot: {
      id: null,
      session_id: null,
      concierge_id: null,
      locale: "es",
      estatus: 1, // 1:abierto, 2:cerrado
      mensajes: [],
    },
    chatbots: [],
  }),

  getters: {
    getStatus: (state) => {
      let legends = ["Abierto", "Cerrado"];
      return legends[state.chatbot.estatus - 1];
    },

    mensajes(state) {
      return state.chatbot.mensajes;
    },


  },

  mutations: {

    setChatBot(state, data) {
      if (data) {
        state.chatbot = data;
      }
    },

    pushMensaje(state, mensaje) {
      if (mensaje) {
        state.chatbot.mensajes.push(mensaje);
      }
    },

    finalizar(state,chat_id) {
      if(state.chatbot.id == chat_id){
         state.chatbot = {
           id: null,
           session_id: null,
           concierge_id: null,
           locale: "es",
           estatus: 1, // 1:abierto, 2:cerrado
           mensajes: [],
         };
      }
     
    }
  },

  actions: {
    fetchIfActivo({ commit }, ch_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/chatbot/${ch_id}/fetch-data-if-active`)
          .then(({ data }) => {
            if (data.result) {
              commit("setChatBot", data.chatbot);
            }
            resolve(data);
          })
          .catch((e) => reject(e));
      });
    },

    aperturar({ commit }, datos) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/chatbot/aperturar-chat`, datos)
          .then(({ data }) => {
            if (data.result) {
              commit("setChatBot", data.chatbot);
              localStorage.setItem("chatbot_id", data.chatbot.id);
            }
            resolve(data);
          })
          .catch((e) => reject(e));
      });
    },

    enviarMensaje({commit},datos){
      return new Promise((resolve,reject) => {
        axios.post(`/api/chatbot/enviar-mensaje`,datos).then(({data}) => {
          commit("pushMensaje", {
            ...datos,
            id:data.mensaje_usuario_id,
          });
          
          if(data.result){
            commit('pushMensaje',data.mensaje);
          }
          resolve(data);
        }).catch((e) => reject(e));
      });
    }
  },
};

