import { headers } from "@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
    
    canal:{
      id:null,
      nombre:'',
      mac_address_reproductor:'',
      cliente_id:null,
      horientacion:1, // 1 => horizontal, 2 => vertical
      display_id:null,
      compra_id:null,
      activo:false,
      configurado:false,
      cliente:null,
      display:null,
      compra:null,
      programaciones:[],
      estatus:1, // 1 => aire , 2 => aire sin conexion, 3 => sin conexion , 4 => sin contratación.
      plan_id:null,
      plan:null,
      fecha_vencimiento:null,
    },

    canales:[],
    optionsEstatus :[
      {text:'Activo',value:1},
      {text:'Activo sin conexión',value:2},
      {text:'Inactivo',value:3},
      {text:'Sin contratación',value:4}
    ]
  }),

  getters:{
    draft:(state) => clone(state.canal),
    nombreCanal:(state) => state.canal.nombre,
    optionsEstatus:(state) => state.optionsEstatus
  },



  mutations:{

    clear(state){
      state.canal = {
        id:null,
        nombre:'',
        mac_address_reproductor:'',
        cliente_id:null,
        horientacion:1, // 1 => horizontal, 2 => vertical
        display_id:null,
        compra_id:null,
        activo:false,
        configurado:false,
        cliente:null,
        display:null,
        compra:null,
        programaciones:[],
        plan_id:null,
        plan:null,
        fecha_vencimiento:null,
      }
    },


    setCanales(state,canals){
      if(canals){
        state.canales = canals
      }
    },


    setCanal(state,canal){
      if( canal){
        state.canal = canal
      }
    },

    update(state,canal){
      let i = state.canales.findIndex(c => c.id == canal.id)

      if(i != -1){
        state.canales[i] = canal
      }

      if(state.canal.id == canal.id){
        state.canal = canal
      }
    },

    pushProgramacion(state,programacion){

      if(state.canal.id == programacion.canal_id){

        let i = state.canal.programaciones.findIndex(p => p.id == programacion.id)

        if(i != -1){
          state.canal.programaciones[i] = programacion
        }else{
          state.canal.programaciones.push(programacion)
        }
      }
    }


  },


  actions:{

    fetch({commit},canal_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/${canal_id}/fetch-data`).then(({data}) => {
          commit('setCanal',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/canals/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchPlayList({commit},canal_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/${canal_id}/play-list`).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e));
      });
    },



    guardar({commit},datos){
      return new Promise((resolve, reject) => {
          axios.put(`/api/canals/${datos.id}`,datos).then(({data}) => {
            if(data.result){
              commit('update',data.canal)
            }
            resolve(data)
          }).catch(e => reject(e))
      })
    },

    eliminar({state,commit},canal_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/canals/${canal_id}`).then(({data}) => resolve(data)).catch(e => reject(e))
      })
    },


    finalizarConfiguracion({commit,dispatch},datos){
      return new Promise((resolve, reject) => {
        dispatch('guardar',datos).then((data) => {
          resolve(data)
        }).catch(e => reject(e))
      })
     
    },

    getProgramacionPorRegion({state,commit},region_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/${state.canal.id}/programacion/region/${region_id}`).then(({data}) => {
          commit('pushProgramacion',data)
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    asignarProgramacion({commit,state},region_id){
      return new Promise((resolve, reject) => {
        axios.put(`/api/canals/${state.canal.id}/programacion/asignar-programacion`,{region_id}).then(({data}) => {
          if(data.result){
            commit('pushProgramacion',data.programacion)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getCanalesPorCliente({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/cliente/${cliente_id}/get-all`).then(({data}) => {
          commit('setCanales',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    getCanalesActivosPorCliente({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/activos/cliente/${cliente_id}/get-all`).then(({data}) => {
          commit('setCanales',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },


    getCanalesPorClienteConProgramacion({state,commit},cliente_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/cliente/${cliente_id}/get-all/con-programacion`).then(({data}) => {
          commit('setCanales',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    fetchProgramacionIdRegion({state,commit},region_id){

      return new Promise((resolve, reject) => {

        axios.get(`/api/canals/${state.canal.id}/programacion-id/region/${region_id}`)
        .then(({data}) => {
          resolve(data)
        })
        .catch(e => reject(e));

      });

    },


    fetchCanalPorNombre({commit},nombre){
      return new Promise((resolve, reject) => {
        axios.get(`/api/canals/nombre/${nombre}`).then(({data}) => {
          commit('setCanal',data)
          resolve(data)
        }).catch(e => reject(e))
      })
    },
    
    updateEstatus({commit},{canal_id,estatus}){
      return new Promise((resolve, reject) => {
        
        axios.put(`/api/canals/${canal_id}/change-estatus`,{estatus}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))

      })
    },

    asignarMedio({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/canals/${datos.canal_id}/asignar-medio`,{medio_id:datos.medio_id}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    asignarReproductor({commit},datos){
      return new Promise((resolve, reject) => {
        axios.put(`/api/canals/${datos.canal_id}/asignar-reproductor`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })

    },

    guardarObservacion({commit},{id,observacion}){
      return new Promise((resolve, reject) => {
        axios.put(`/api/canals/${id}/set-observacion`,{observacion}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }

  }

}