import ability from './ability'

export const canNavigate = (to) => {
  if (!to.matched || to.matched.length === 0) {
    return false;
  }

  return to.matched.some((route) => {
    const action = route.meta.action;
    const resource = route.meta.resource;
    
    // Verificar si action y resource están definidos
    if (!action || !resource) {
      return false;
    }

    return ability.can(action, resource);
  });
};

export const _ = undefined;