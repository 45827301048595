import { headers } from "../../@core/utils/utils"

export default {
  namespaced:true,
  state:() =>({
  
    menu:{
      id           : null,
      titulo       : '',
      sub_titulo   : '',
      fondo        : null,
      activa       : false,
      is_submenu   : false,
      concierge_id : null,
      pagina_id    : null,
      menu_padre_id: null,
      concierge    : null,
      pagina       : null,
      url_fondo    : null,
      con_submenus : false,
      orden:0,
    },

    menus:[],
  }),

  getters:{
    draft:(state) => clone(state.menu),
  },



  mutations:{

    clear(state){
      state.menu = {
        id           : null,
        titulo       : '',
        sub_titulo   : '',
        fondo        : null,
        activa       : false,
        is_submenu   : false,
        concierge_id : null,
        pagina_id    : null,
        menu_padre_id: null,
        concierge    : null,
        pagina       : null,
        url_fondo    : null,
        con_submenus : false,
        orden        : 0,

      }
    },


    setMenus(state,menus){
      if(menus){
        state.menus = menus
      }
    },


    setMenu(state,menu){
      if( menu){
        state.menu = menu
      }
    },



    update(state,menu){
      let i = state.menus.findIndex(c => c.id == menu.id)

      if(i != -1){
        state.menus[i] = menu
      }

      if(state.menu.id == menu.id){
        state.menu = menu
      }
    },

    setUrlFondo(state,url){
      if(url){
        state.menu.url_fondo = url
      }
    },
    pushMenu(state,menu){
      if(menu){
        state.menus.push(menu)
      }
    },

    quitarMenu(state,menu_id){
      
      let i = state.menus.findIndex(c => c.id == menu_id)
      if(i != -1){
        state.menus.splice(i,1)
      }

    },

    reordenarMenu:(state,{element,newIndex,oldIndex}) => {

      let menu_ant_ind = state.menus.findIndex( v => v.orden == newIndex); // encontrar el menu afectado por el cambio del menu cambiado
      if(menu_ant_ind != -1){
        state.menus[menu_ant_ind].orden = oldIndex
      }

      let menu_new_ind = state.menus.findIndex(v => v.id === element.id);

      if(menu_new_ind != -1){
        state.menus[menu_new_ind].orden = newIndex
      }

    },

  },


  actions:{

    fetch({commit},menu_id){

      return new Promise((resolve, reject) => {
        axios.get(`/api/menus/${menu_id}/fetch-data`).then(({data}) => {
          commit('setMenu',data)
          resolve(data)
        }).catch(e => reject(e))

      })
    },


    fetchData({commit,dispatch},datos){

      return new Promise((resolve, reject) => {
        axios.post(`/api/menus/fetch-data`,datos).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    guardar({commit},datos){

      let formData = new FormData();
      Object.keys(datos).forEach(key => {
        if(datos[key] != null){
          formData.append(key,datos[key])
        }
      });
      
      return new Promise((resolve, reject) => {
        if(datos.id){
          formData.append('_method','PUT')
           axios.post(`/api/menus/${datos.id}`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('update',data.menu)
              commit('clear');

            }
            resolve(data)
          }).catch(e => reject(e))
        }else{
           axios.post(`/api/menus`,formData,{headers}).then(({data}) => {
            if(data.result){
              commit('clear');
              commit('pushMenu',data.menu)
            }
            resolve(data)
          }).catch(e => reject(e))
        }
         
      })
    },

    eliminar({state,commit},menu_id){
      return new Promise((resolve, reject) => {
        axios.delete(`/api/menus/${menu_id}`).then(({data}) => {
          if(data.result){
            commit('quitarMenu',menu_id)
          }
          resolve(data)
        }).catch(e => reject(e))
      })
    },

    cargarMenusPadre({state},concierge_id) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/menus/concierges/${concierge_id}/menus-padre`).then(({data}) => {
        
          resolve(data)
        }).catch(e => reject(e));
        
      })
    },
    getMenusConcierge({commit},concierge_id){
      return new Promise((resolve, reject) => {
        axios.get(`/api/menus/concierges/${concierge_id}/fetch-menus`).then(({data}) => {
          commit('setMenus',data)
          resolve(data)
        }).catch(e => reject(e));
      });
    },

    showMenu({commit,state},{menu_id,next}){
      return new Promise((resolve, reject) => {
        axios.get(`/api/menus/${menu_id}/show-menu`).then(({data}) => {
          if(data.menus && data.menus.length){
            commit('setMenus',data.menus)
          }else{
            next(data)
          }
          resolve(data);
        }).catch(e => reject(e))

      })
    },

    reordenar({commit,state}){
      return new Promise((resolve, reject) => {
        axios.post(`/api/menus/reordenar-menus`,{menus:state.menus}).then(({data}) => {
          resolve(data)
        }).catch(e => reject(e))
      })
    }
  }

}